<template>
    <section class="invoice-preview-wrapper">

        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="invoice === undefined">
            <h4 class="alert-heading">
                Error fetching invoice data
            </h4>
            <div class="alert-body">
                No invoice found with this invoice id. Check
                <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
                    Invoice List
                </b-link>
                for other invoices.
            </div>
        </b-alert>

        <b-row v-if="invoice" class="invoice-preview">

            <!-- Col: Left (Invoice Container) -->
            <b-col cols="12" xl="9" md="8">
                <b-card no-body class="invoice-preview-card">
                    <!-- Header -->
                    <b-card-body class="invoice-padding pb-0">

                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                            <!-- Header: Left Content -->
                            <div>
                                <div class="logo-wrapper">
                                    <div class="logo-wrapper">
                                        <b-img :src="AliffLogo" alt="logo" class="fp_logo mb-5" />
                                    </div>
                                </div>
                                <p>From:</p>
                                <p class="card-text mb-25">
                                    Office 149, 450 South Brand Brooklyn
                                </p>
                                <p class="card-text mb-25">
                                    San Diego County, CA 91905, USA
                                </p>
                                <p class="card-text mb-0">
                                    +1 (123) 456 7891, +44 (876) 543 2198
                                </p>
                                <p class="card-text mb-0">
                                    {{ invoice.user_email }}
                                </p>
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0 mt-2">
                                <h4 class="invoice-title">
                                    Invoice
                                    <!-- <span class="invoice-number">#{{ invoice.sim_id }}</span> -->
                                    <span class="invoice-number">2A</span>
                                </h4>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">
                                        Date Issued:
                                    </p>
                                    <p class="invoice-date">
                                        {{ formatDate(invoice.issued_date) }}
                                    </p>
                                </div>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">
                                        Due Date:
                                    </p>
                                    <p class="invoice-date">
                                        {{ formatDate(invoice.due_date) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </b-card-body>

                    <!-- Spacer -->
                    <hr class="invoice-spacing">

                    <!-- Invoice Client & Payment Details -->
                    <b-card-body class="invoice-padding pt-0">
                        <b-row class="invoice-spacing">

                            <!-- Col: Invoice To -->
                            <b-col cols="12" xl="6" class="p-0">
                                <h6 class="mb-2">
                                    Invoice To:
                                </h6>
                                <h6 class="mb-25">
                                    Aliff overseas Pvt Ltd
                                </h6>
                                <p class="card-text mb-25">
                                    203, God’s Gift Tower, Next to Lucky Hotel,
                                    S.V.Road, Bandra (W), Mumbai
                                </p>
                                <p class="card-text mb-0">
                                    accounts@aliff.in
                                </p>
                            </b-col>

                            <!-- Col: Payment Details -->
                            <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">
                                <div v-if="invoice.bank_account && invoice.bank_account.ba_number">
                                    <h6 class="mb-2">
                                        Payment Details:
                                    </h6>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td class="pr-1">
                                                    Total Due:
                                                </td>
                                                <td><span class="font-weight-bold">₹{{ invoice.total_commission_amount
                                                        }}</span></td>
                                            </tr>
                                            <tr>
                                                <td class="pr-1">
                                                    Bank name:
                                                </td>
                                                <td>{{ invoice.bank_account.ba_name }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pr-1">
                                                    Beneficiary Name:
                                                </td>
                                                <td>{{ invoice.bank_account.ba_benificiary_name }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pr-1">
                                                    Bank Account Number:
                                                </td>
                                                <td>{{ invoice.bank_account.ba_number }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pr-1">
                                                    Routing Number:
                                                </td>
                                                <td>{{ invoice.bank_account.ba_routing_number }}</td>
                                            </tr>
                                            <!-- <tr>
                      <td class="pr-1">
                        SWIFT code:
                      </td>
                      <td>{{ invoice.swiftCode }}</td>
                    </tr> -->
                                        </tbody>
                                    </table>
                                </div>
                                <div v-else>
                                    Payment details not available , Add From My Profile.
                                </div>
                            </b-col>
                            <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-start">
                                <div v-if="invoice.signature && invoice.signature.sg_file_path">
                                    <b-img :src="`${FILESURL}${invoice.signature.sg_file_path}`" alt="signature" />
                                </div>
                                <div v-else>
                                    Signature not available , Add From My Profile.
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!-- Invoice Description: Table -->
                    <b-table-lite responsive :items="invoice.transactions" :fields="[
                        { key: 'sr_no', label: 'Sr No' },
                        { key: 'services', label: 'Services Taken By Students' },
                        { key: 'payments', label: 'Payments' }
                    ]">
                        <template #cell(sr_no)="data">
                            <b-card-text class="font-weight-bold mb-25">
                                {{ data.index + 1 }}
                            </b-card-text>
                        </template>
                        <template #cell(services)="data">
                            <b-card-text class="text-nowrap">
                                {{ data.item.user_name }} - {{ data.item.service_name }} - {{ data.item.plan_name }}
                            </b-card-text>
                        </template>
                        <template #cell(payments)="data">
                            <b-card-text class="text-nowrap">
                                ₹{{ data.item.commission_amount }}
                            </b-card-text>
                        </template>
                    </b-table-lite>

                    <!-- Invoice Description: Total -->
                    <b-card-body class="invoice-padding pb-0">
                        <b-row>

                            <!-- Col: Sales Persion -->
                            <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
                                <!-- <b-card-text class="mb-0">
                    <span class="font-weight-bold">Salesperson:</span>
                    <span class="ml-75">Alfie Solomons</span>
                  </b-card-text> -->
                            </b-col>

                            <!-- Col: Total -->
                            <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                                <div class="invoice-total-wrapper">
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">
                                            Subtotal:
                                        </p>
                                        <p class="invoice-total-amount">
                                            ₹{{ invoice.total_commission_amount }}
                                        </p>
                                    </div>
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">
                                            TDS:
                                        </p>
                                        <p class="invoice-total-amount">
                                            2%
                                        </p>
                                    </div>
                                    <hr class="my-50">
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">
                                            Total:
                                        </p>
                                        <p class="invoice-total-amount">
                                            ₹{{ invoice.final_commission_amount }}
                                        </p>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!-- Spacer -->
                    <hr class="invoice-spacing">

                    <!-- Note -->
                    <b-card-body class="invoice-padding pt-0 text-center">
                        <span class="font-weight-bold">Note: </span>
                        <span>It was a pleasure working with you and your team.
                            Thank You!</span>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Right Col: Card -->
            <b-col cols="12" md="4" xl="3" class="invoice-actions">
                <b-card>

                    <!-- Button: Send Invoice -->
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="approveInvoice" variant="primary"
                        v-if="invoice.sims_id === 2" class="mb-75" block>
                        Approve
                    </b-button>

                    <!-- Button: DOwnload -->
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mb-75"
                        block>
                        Download
                    </b-button>

                    <!-- Button: Print -->
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-primary" class="mb-75" block
                        @click="printInvoice">
                        Print
                    </b-button>

                </b-card>
            </b-col>
        </b-row>

    </section>

</template>

<script>
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';
import CommonServices from '@/apiServices/CommonServices';
import { FILESURL } from "@/config";
import AliffLogo from "@/theme-variables/assets/Logo.png";
import store from "@/store";
import { invoke } from '@vueuse/core';
import AgentServices from '@/apiServices/AgentServices';
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import moment from 'moment';

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BTableLite,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BImg
    },

    directives: {
        Ripple,
        'b-toggle': VBToggle,
    },

    data() {
        return {
            fields: [
                { key: 'service', label: 'Service' },
                { key: 'description', label: 'Description' },
                { key: 'amount', label: 'Amount' },
                { key: 'actions', label: 'Actions', class: 'text-center' }
            ],
            invoiceDetails: [
                // Sample data
                { service: 'Consultation', description: 'Consultation services', amount: '$100' },
                { service: 'Development', description: 'Software development services', amount: '$500' }
            ],
            invoice: null,
            FILESURL,
            AliffLogo
        };
    },
    computed: {
        user_type() {
            if (store.getters["user/getUserDetails"].user_type) {
                let user_type = store.getters["user/getUserDetails"].user_type;
                return user_type;
            }
            return null;
        },
        logo() {
            const user_details = this.$store.getters["user/getUserDetails"];
            if (user_details.brand_logo) {
                return `${FILESURL}${user_details.brand_logo}`;
            }
            return AliffLogo;
        },
    },
    methods: {
        moment,
        printInvoice() {
            document.querySelectorAll(".dont-print").forEach((el) => {
                el.classList.add("print-show");
                el.classList.remove("dont-print");
            });

            window.print();

            setTimeout(() => {
                document.querySelectorAll(".print-show").forEach((el) => {
                    el.classList.add("dont-print");
                    el.classList.remove("print-show");
                });
            }, 5000);

        },
        async approveInvoice() {
            try {
                if (!this.invoice.bank_account.ba_number) {
                    this.$toast({
                        component: ToastificationContentVue,
                        props: {
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                            title: "Bank Account Details not available",
                        },
                    });
                    return;
                }
                if (!this.invoice.signature.sg_file_path) {
                    this.$toast({
                        component: ToastificationContentVue,
                        props: {
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                            title: "Signature not available",
                        },
                    });
                    return;
                }

                const response = await AgentServices.approveServiceInvoice({ sim_id: this.invoice.sim_id, sims_id: 3 });
                if (response.data.status) {
                    this.getInvoice(this.invoice.student_user_id);
                    this.$toast({
                        component: ToastificationContentVue,
                        props: {
                            icon: "CheckIcon",
                            variant: "success",
                            title: "Invoice Approved",
                        },
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getInvoice() {
            try {
                const invoice_ids = JSON.parse(localStorage.getItem('invoice_ids'));
                const response = await CommonServices.getClubbedServiceCommissionInvoice({ invoice_ids });
                if (response.data.status) {
                    this.invoice = response.data.data[0]
                }
            } catch (error) {
                console.error("Error getting clubbed invoice", error);
            }
        },
        formatDate(date) {
            return moment(date, 'YYYY-MM-DD').format('DD MMM YYYY');
        }
    },
    beforeMount() {

        this.getInvoice();

    }
};
</script>



<style lang="scss" scoped>
.fp_logo {
    height: 50px;
    width: 50px;
    margin-bottom: 35px;
}

@import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style lang="scss">
@media print {

    .dont-print {
        display: block;
    }

    .print-show {
        display: none;
    }

    body {
        background-color: transparent !important;
        display: block !important;
    }

    nav.header-navbar {
        display: none;
    }

    .main-menu {
        display: none;
    }

    .content-header {
        display: block !important;
    }

    .header-navbar-shadow {
        display: none !important;
    }

    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }

    footer.footer {
        display: none;
    }

    .card {
        background-color: transparent;
        box-shadow: none;
    }

    .customizer-toggle {
        display: none !important;
    }

    .invoice-preview-wrapper {
        display: block !important;

        body {
            display: block !important;
        }

        .invoice-actions {
            display: none !important;
        }
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {
        .row.invoice-preview {
            .col-md-8 {
                max-width: 100%;
                flex-grow: 1;
            }

            .invoice-preview-card {
                .card-body:nth-of-type(2) {
                    .row {
                        >.col-12 {
                            max-width: 50% !important;
                        }

                        .col-12:nth-child(2) {
                            display: flex;
                            align-items: flex-start;
                            justify-title: flex-end;
                            margin-top: 0 !important;
                        }
                    }
                }
            }
        }

        // Action Right Col
        .invoice-actions {
            display: none;
        }
    }
}
</style>